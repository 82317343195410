.sibling-navigation {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -0.25rem;
  margin-bottom: 2rem;
  padding-left: 0;

  li {
    margin: 0 0.25rem 0.25rem 0.25rem;
    list-style: none;
    text-align: center;

    a {
      @include headline-font;
      @include button-default;
      display: inline-block;
      padding: 0.65rem;
      border-radius: 0.75rem;
      text-decoration: none;
      text-transform: uppercase;
      white-space: nowrap;
      font-size: 11px;

      @media (min-width: $breakpoint-small) {
        font-size: 18px;
      }

    }

  }

}
