.banners-and-slider-container {
  padding-bottom: 2rem;

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  .square-baner {
    border-right: 2px solid $c-yellow;
    border-left: 2px solid $c-yellow;
  }

  .square-baner_left {
    img {
      border-radius: 0.75rem 0 0 0.75rem;
    }
  }

  .square-baner_right {
    img {
      border-radius: 0 0.75rem 0.75rem 0;
    }
  }

  .uk-slider-items {

    li {
      width: 100%;
    }

    img {
      border-right: 2px solid $c-yellow;
      border-left: 2px solid $c-yellow;
    }

  }

  @media (max-width: $breakpoint-small) {
    .square-baner_left {
      order: 2;
      img {
        border-radius: 0 0 0 0.75rem;
      }
    }

    .square-baner_right {
      order: 3;
      img {
        border-radius: 0 0 0.75rem 0;
      }
    }

    .baner {
      order: 1;
      border-bottom: 4px solid $c-yellow;
      img {
        border-radius: 0.75rem 0.75rem 0 0;
      }
    }
  }

}

/*
.banners-and-slider-container {
  padding-bottom: 2rem;

  .uk-slider-items {

    img {
      display: block;
      width: 100%;
      height: auto;
      border-right: 2px solid $c-yellow;
      border-left: 2px solid $c-yellow;
      border-radius: 20px;
    }

  }

  .uk-slidenav {
    padding: 5px 40px;
    background-color: $c-yellow;

    svg {
      padding: 15px;
      width: 60px;
      height: 60px;
      border-radius: 20px;
      color: $c-gray;

      &:hover {
        background-color: $c-red;
        color: $c-yellow;
      }

    }
  }

}
*/
