.menu-item {
  margin-bottom: 0.4rem;
  //box-sizing: border-box;
  border-radius: 1rem;
  background-color: $c-yellow-darken;

  @media (min-width: $breakpoint-small) {
    display: flex;
  }

  .item-info {
    flex-grow: 1;
    //box-sizing: border-box;
    padding: 0.5rem;
    padding-bottom: 0;
    text-align: center;

    @media (min-width: $breakpoint-small) {
      padding-bottom: 0.5rem;
      text-align: left;
    }

  }

  .headline {
    margin-bottom: 0;
    color: $c-gray;
    font-size: 1.2rem;
  }

  .quantity {
    margin-right: 0.25rem;
  }

  .notice {
    margin: 0;
    color: $c-gray;
    font-size: 0.9rem;
  }

  .item-price {
    //box-sizing: border-box;
    padding: 0.5rem;
    padding-top: 0;
    text-align: center;

    @media (min-width: $breakpoint-small) {
      flex-basis: 220px;
      flex-shrink: 0;
      padding-top: 0.5rem;
      text-align: right;
    }
  }

  .price {
    @include headline-font;
    font-size: 1.3rem;
  }

  .add-to-cart {
    margin-top: 0.5rem;
    width: auto;
    border-radius: 1rem;

    @media (min-width: $breakpoint-small) {
      width: 100%;
    }

  }

}
