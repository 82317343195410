/* FONTS - BEGIN  */
@mixin base-font {
  font-weight: 400;
  font-family: 'Roboto Slab', serif;
}

@mixin base-font-bold {
  font-weight: 600;
  font-family: 'Roboto Slab', serif;
}

@mixin headline-font {
  font-weight: 700;
  font-family: 'Alegreya Sans', sans-serif;
}

@mixin headline-font-bold {
  font-weight: 900;
  font-family: 'Alegreya Sans', sans-serif;
}
/* FONTS - END  */

/* COLORS - BEGIN */
$c-green: #185018;
//$c-green-lighter: lighten($c-green, 15%);
$c-yellow: rgb(231, 194, 29);
$c-yellow-darken: darken($c-yellow, 5%);
$c-red: #830b0c;
$c-gray: rgb(51, 51, 51);
$c-gray-lighter: lighten($c-gray, 15%);
$c-gray-super-lighter: lighten($c-gray, 60%);
$c-white: #ffffff;
/* COLORS - END */

$border-radius: 20%;


@mixin clearfix {
  &:after {
    content: "";
    clear: both;
    display: block;
  }
}
