#basket-miniwindow {
  position: fixed;
  top: -2px;
  left: 50%;
  max-width: 100vw;
  width: auto;
  transform: translateX(-50%);

  button {
    @include headline-font-bold;
    position: relative;
    padding: 0.4em 0.3em;
    border: none;
    border-radius: 0 0 1rem 1rem;
    box-shadow: 0 1px 4px rgba($c-gray, 0.8), 0 0 40px rgba($c-gray, 0.3) inset;
    color: $c-red;
    text-transform: uppercase;
    white-space: nowrap;
    font-size: 1.3rem;
    background-color: $c-white;

    &:hover {
      background-color: $c-red;
      color: $c-yellow;
    }

  }
}
