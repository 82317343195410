body {
  @include base-font;
  color: $c-green;
  background-color: $c-yellow;

  strong {
    @include base-font-bold;
  }

  em {
    color: $c-green;
  }

}

h1 {
  @include headline-font-bold;
  color: $c-red;

}

h2, h3, h4, h5, h6 {
  @include headline-font;
  color: $c-gray;

  strong {
    @include headline-font-bold;
  }

}


// default button mixin
@mixin button-default {
  border: none;
  background: linear-gradient(180deg, $c-green 90%, darken($c-green, 5%) 10%);
  color: $c-yellow;

  &.uk-active {
    background: linear-gradient(180deg, $c-red 90%, darken($c-red, 5%) 10%);
  }

  &:hover {
    color: $c-white;
  }

}

// default button (pres tridu)
.uk-button.uk-button-default {
  @include button-default;
}


input,
textarea,
select,
button {
  @include base-font;
  font-size: 1rem;
}


input[type="checkbox"] {
  display: inline-block;
  overflow: hidden;
  box-sizing: border-box;
  margin: 0;
  margin-top: -4px;
  width: 16px;
  height: 16px;
  border-radius: 0;
  background-color: #ebebeb;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  vertical-align: middle;
  font: inherit;
  cursor: pointer;
}
