.main-navigation {

  @media (min-width: $breakpoint-small) {
    margin-top: 1em;
  }

  li {
    @include button-default;
    margin-bottom: 0.5em;
    border-right: 2px solid $c-yellow;
    border-left: 2px solid $c-yellow;
    border-radius: 20px;
    border-radius: 0.75rem;
    text-align: center;

    @media (min-width: $breakpoint-small) {
      border-radius: 0;
    }

    a {
      @include headline-font;
      display: inline-block;
      box-sizing: border-box;
      padding: 0.75rem;
      width: 100%;
      color: $c-yellow;
      text-transform: uppercase;
      font-size: 11px;

      &:hover {
        color: $c-white;
        text-decoration: none;
      }

      @media (min-width: $breakpoint-small) {
        padding: 0.5rem;
        font-size: 18px;
      }

    }

    @media (min-width: $breakpoint-small) {

      &:first-child {
        border-left: none;
        border-radius: 0.75rem 0 0 0.75rem;
      }

      &:last-child {
        border-right: none;
        border-radius: 0 0.75rem 0.75rem 0;
      }

    }

  }

}
