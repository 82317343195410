.top-strip-container {

  @media (min-width: $breakpoint-small) {
    background: linear-gradient(180deg, $c-green 92%, $c-yellow 8%);
  }

}

.top-strip {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1260px;

  .openings {
    @include base-font-bold;
    order: 1;
    box-sizing: border-box;
    padding: 1em 15px;
    width: 50%;
    color: $c-gray;
    text-align: left;
    font-size: 16px;

    @media (min-width: $breakpoint-small) {
      order: 0;
      padding-right: 0;
      padding-left: 30px;
      width: 25%;
      color: $c-yellow;
    }

  }

  .main-logo {
    order: 0;
    box-sizing: border-box;
    padding: 1em 1em 0 1em;
    width: 100%;
    background: linear-gradient(180deg, $c-green 92%, $c-yellow 8%);

    @media (min-width: $breakpoint-small) {
      order: 1;
      width: 50%;
    }

    img {
      display: inline-block;
      width: 100%;
      height: auto;
    }
  }

  .language-switcher {
    @include base-font-bold;
    order: 2;
    box-sizing: border-box;
    padding: 1em;
    width: 50%;
    color: $c-gray;
    text-align: right;
    font-size: 18px;

    @media (min-width: $breakpoint-small) {
      padding-right: 30px;
      padding-left: 0;
      width: 25%;
    }

    a {
      display: inline-block;
      padding: 0.3em;
      border: 2px solid $c-green;
      border-radius: $border-radius;
      background-color: transparent;
      color: $c-green;

      @media (min-width: $breakpoint-small) {
        border: 2px solid $c-yellow;
        color: $c-yellow;
      }

      &:hover {
        background-color: $c-green;
        color: $c-white;
        text-decoration: none;

        @media (min-width: $breakpoint-small) {
          background-color: $c-yellow;
          color: $c-green;
        }

      }

    }

  }

}
