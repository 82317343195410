// UIkit default variables & mixins
@import "~uikit/src/scss/variables.scss";
@import "~uikit/src/scss/mixins.scss";

// UIkit
@import "~uikit/src/scss/uikit.scss";

// variables
@import "./styles/variables";

// typography
@import "./styles/_typography";

// top strip
@import "./styles/_top-strip";

// main menu
@import "./styles/_main-navigation";

// baners & slider
@import "./styles/_banners";

// footer
@import "./styles/_footer";

// sibling menu
@import "./styles/_sibling-navigation";

// menu
@import "./styles/_menu-group-switcher";
@import "./styles/_menu-group";
@import "./styles/_menu-item";
@import "./styles/_basket-mini";
@import "./styles/_basket-and-order";

// contact form
@import "./styles/_contact-form";

// markdown content
@import "./styles/_markdown-content";


/* HISTORY PAGE - BEGIN */
body.page_template___history {

  #basket-miniwindow {
    visibility: hidden;
  }

  .menu-group {
    text-align: center;
  }

  #bottom-langswitcher {
    position: fixed;
    bottom: 8px;
    left: 0;
    width: 100%;
    text-align: center;

    a {
      margin: 0 3px;
      padding: 9px 9px;
      font-weight: bold;
      border-radius: 8px 8px 0 0;
      color: $c-yellow;
      background-color: $c-red;
    }

  }

}
/* HISTORY PAGE - END */
