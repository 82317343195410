#contact-form-container {

  .uk-close {
    color: $c-yellow;
    &:hover {
      color: $c-white;
    }
  }

  .uk-modal-dialog {
    background-color: transparent;
  }

  .uk-modal-header {
    border-radius: 20px 20px 0 0;
    background-color: $c-green;

    .uk-modal-title {
      color: $c-yellow;
    }

  }

  .uk-modal-body {
    border-radius: 0 0 20px 20px;
    background-color: $c-yellow;

    .form-field {
      margin: 1em;
    }

    #contact_form_result_container {
      margin: 1em;
    }

    .buttons {
      margin: 16px;
    }

    input,
    textarea {
      display: inline-block;
      box-sizing: border-box;
      padding: 10px;
      max-width: 100%;
      width: 100%;
      border: 0 none;
      background: #f8f8f8;
      color: #666;
      vertical-align: middle;
      line-height: 1.6;
      outline: none;
    }

    button {
      border: none;
      background: linear-gradient(180deg, #185018 90%, #123c12 10%);
      color: #e7c21d;
      font-weight: 700;
      font-family: "Alegreya Sans", sans-serif;
      padding: 0.5rem;
      border-radius: 0.5rem;
      text-transform: uppercase;
      font-size: 1.2rem;
    }

  }

}
