footer {
  margin-top: 2rem;
  padding: 1em 1em 2em 1em;
  background-color: $c-green;
  color: $c-white;
  text-align: center;

  h1 {
    margin-bottom: 0;
    color: $c-yellow;
  }

  .address {
    @include base-font-bold;
    margin-top: 0;
    margin-bottom: 0;
  }

  a {
    color: $c-white;
    text-decoration: underline;
    &:hover {
      color: $c-yellow;
    }
  }

  .uk-button.facebook {
    border-radius: 10px;
    background-color: rgb(66,103,178);
    &:hover {
      background-color: darken(rgb(66,103,178), 10%);
    }
  }

  .buttons {
    line-height: 48px;

    .uk-button.map,
    .uk-button.contact-form {
      @include base-font-bold;
      border-radius: 10px;
      background-color: $c-white;
      color: $c-green;
      text-transform: uppercase;
      &:hover {
        background-color: darken($c-white, 10%);
      }
    }
  }

  p.links {

    a {
      padding: 0.5rem;
      white-space: nowrap;
      font-size: 0.8rem;
    }

  }

}
