/* basket & order window - BEGIN */
#basket-and-order-window {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000000;
  overflow: auto;
  box-sizing: border-box;
  padding: 2em;
  min-height: 100vh;
  width: 100%;
  height: 100%;
  background-color: $c-yellow;

  .buttons button {
    @include button-default;
    @include headline-font;
    padding: 0.5rem;
    border-radius: 0.5rem;
    text-transform: uppercase;
    font-size: 1.2rem;
  }

  .basket-window-close-container {
    text-align: right;

    .basket-window-close {
      outline: none;
      border: none;
      border-radius: 0.5rem;
      background-color: transparent;

      &:hover {
        background-color: $c-white;
        color: $c-yellow;
      }

    }

  }

  h1 {
    text-align: center;
  }
}
/* basket & order window - END */


/* basket - BEGIN */
#basket-container {

  .item {
    @include clearfix;

    &:after {
      padding-top: 0.5rem;
      border-bottom: 2px solid $c-yellow-darken;
    }

    .text {
      padding-top: 0.75rem;
      padding-bottom: 0.5rem;
      width: 100%;
      /*
      @media (min-width: $breakpoint-small) {
        width: 70%;
        float: left;
      }
      */

      * {
        margin-top: 0;
        margin-bottom: 0;
      }

      .notice {
        color: $c-gray;
        font-size: 0.8rem;
        line-height: 1.2;
      }

    }

    .count {
      float: left;
      width: 50%;

      input {
        float: left;
        width: 4em;
      }

      button {
        display: block;
        float: left;
        box-sizing: border-box;
        width: 40px;
        height: 40px;
        border: none;
        background-color: $c-gray-lighter;
        color: $c-white;
        cursor: pointer;

        &:hover {
          background-color: $c-gray;
        }

      }

    }

    .price {
      float: right;
      width: 50%;
      text-align: right;
      font-size: 1.2rem;
      line-height: 40px;
    }

  }

  .total-price {
    strong {
      font-size: 1.2rem;
    }
  }

}
/* basket - END */


/* order - BEGIN */
#order-container {

  h2 {
    color: $c-red;
    text-align: center;
  }

  .form-field {
    margin-bottom: 1rem;

    @media (min-width: $breakpoint-small) {
      &.name,
      &.phone,
      &.email {
        float: left;
        box-sizing: border-box;
        padding-right: 1rem;
        width: 33%;
      }
    }

  }

  label {
    color: $c-gray;
    text-align: center;
  }

  input,
  textarea {
    &:not([type="radio"]):not([type="checkbox"]) {
      display: inline-block;
      box-sizing: border-box;
      padding: 0 10px;
      max-width: 100%;
      width: 100%;
      border: 0 none;
      background: #f8f8f8;
      color: #666;
      vertical-align: middle;
    }
  }

  input {
    &:not([type="radio"]):not([type="checkbox"]) {
      height: 40px;
    }
  }

  textarea {
    height: 5rem;
  }


}
/* order - END */


/* shipping zones - BEGIN */
#shipping_zones_table_container {
  @include clearfix;

  h3 {
    color: $c-red;
    text-align: center;
  }

  .item {
    @include clearfix;
    margin-bottom: 1rem;

    &:after {
      padding-top: 0.5rem;
      border-bottom: 2px solid $c-yellow-darken;
    }

    .text {
      margin-bottom: 0.5rem;
      font-size: 0.9rem;
      line-height: 1.3;
      label {
        @include base-font-bold;
        color: $c-green;
      }
    }

    .price {
      @include clearfix;
      line-height: 1.1;

      small {
        display: block;
        float: left;
        color: $c-gray;
      }

      .value {
        @include base-font-bold;
        display: block;
        float: right;
      }

    }

  }


}
/* shipping zones - BEGIN */
