/* formatovani markdown fieldu */
.content-markdown {

  img {
    margin-right: 1em;
    margin-bottom: 1em;
    max-width: 100%;
    height: auto;
    box-sizing: border-box;
    float: left;
  }

}
