.group-switcher-controller {
  position: fixed;
  bottom: 0;
  left: 50%;
  max-width: 100vw;
  width: auto;
  transform: translateX(-50%);

  button {
    @include headline-font-bold;
    position: relative;
    border-radius: 1rem 1rem 0 0;
    box-shadow: 0 1px 4px rgba($c-gray, 0.8), 0 0 40px rgba($c-gray, 0.3) inset;
    color: $c-red;
    text-transform: uppercase;
    white-space: nowrap;
    font-size: 1.3rem;
    background-color: $c-white;

    &:hover {
      background-color: $c-red;
      color: $c-yellow;
    }

  }

}

#offcanvas-overlay {
  .uk-offcanvas-bar {
    background-color: $c-red;

    button {
      color: $c-white;
      &:hover {
        color: $c-yellow;
      }
    }

    a {
      @include headline-font;
      color: $c-white;
      &:hover {
        color: $c-yellow;
      }
    }

  }
}
